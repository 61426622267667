/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Container, Row, Col, DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown, Button
} from "reactstrap";
import { FaFacebookF, FaLinkedin, FaTwitter, FaTelegram, FaInstagram } from "react-icons/fa";

function DarkFooter() {
  return (
    <footer className="footer py-4">
      <Container style={{ maxWidth: "90%" }}>
        <Row className="justify-content-center align-items-end">
          <Col lg={3} md={4} sm={12} xs={12} className="">
            <div className="text-center text-lg-left">
              <img src={require('../../assets/img/home/footer-logo.png').default} className="img-fluid footerLogoSize" />
            </div>
          </Col>
          <Col lg={9} md={8} sm={12} xs={12} className="py-3 py-lg-0">
            <div className="text-center text-lg-left">
              <Row className="justify-content-center align-items-center text-left text-lg-center">
                <Col lg={2} md={6} sm={12} xs={12} className="mb-2 borderBottomFooter">
                  <a href="/index">
                    <span className="footerNavText">Home</span>
                  </a>
                </Col>
                <Col lg={2} md={6} sm={12} xs={12} className="mb-2 borderBottomFooter">
                  <a href="/about-us">
                    <span className="footerNavText">About Us</span>
                  </a>
                </Col>
                <Col lg={2} md={6} sm={12} xs={12} className="mb-2 borderBottomFooter">

                  {/* <div className="btn-group dropup">
                    <button type="button" className="btn btn-secondary p-0 m-0" style={{ backgroundColor: "transparent" }}>
                      <a href="/our-brands">
                        <span className="footerNavText">Our Brands</span>
                      </a>
                    </button>
                    <button type="button" style={{ backgroundColor: "transparent" }} className="btn btn-secondary dropdown-toggle dropdown-toggle-split py-0 px-3 m-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="sr-only">Toggle Dropdown</span>
                    </button>
                    <div className="dropdown-menu">
                      <span>AA</span>
                    </div>
                  </div> */}

                  {/* <UncontrolledDropdown group direction="up">
                    <Button color="primary" className="p-0 m-0" href="/our-brands" style={{ backgroundColor: "transparent" }}>
                      <span className="navItemText">Our Brands</span>
                    </Button>
                    <DropdownToggle
                      caret
                      className="py-0 px-2 m-0"
                      style={{ backgroundColor: "transparent" }}
                    />
                    <DropdownMenu>
                      <DropdownItem to="/index" >
                        Yeebet Live
                      </DropdownItem>
                      <DropdownItem to="https://ibcbet77.com/" >
                        Yeebet Sports
                      </DropdownItem>
                      <DropdownItem to="https://yeebet.info/" >
                        Yeebet News
                      </DropdownItem>
                      <DropdownItem to="https://uuslots.info/" >
                        Yeebet Slot
                      </DropdownItem>
                      <DropdownItem to="http://mgcslot.com/" >
                        MGC Slots
                      </DropdownItem>
                      <DropdownItem to="https://www.hcgame888.com/demo/#/" >
                        HC Slots
                      </DropdownItem>
                      <DropdownItem to="https://dkgaming.asia/" >
                        DK Gaming
                      </DropdownItem>
                      <DropdownItem to="https://metapay168.net/" >
                        Metapay
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}

                  <a href="/our-brands">
                    <span className="footerNavText">Our Brands</span>
                  </a>
                </Col>
                <Col lg={2} md={6} sm={12} xs={12} className="mb-2 borderBottomFooter">
                  <a href="/our-games">
                    <span className="footerNavText">Our Games</span>
                  </a>
                </Col>
                <Col lg={2} md={6} sm={12} xs={12} className="mb-2 borderBottomFooter">
                  <a href="/news">
                    <span className="footerNavText">News</span>
                  </a>
                </Col>
                <Col lg={2} md={6} sm={12} xs={12} className="mb-2 borderBottomFooter">
                  <a href="/contact-us">
                    <span className="footerNavText">Contact Us</span>
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="footerBorderBottom mb-4 mt-2" />
        <Row className="justify-content-end align-items-center">
          <Col lg={4} md={11} sm={11} xs={12}>
            <Row className="justify-content-center justify-content-lg-center align-items-center text-center">
              <Col lg={2} md={2} sm={2} xs={2}>
                <a href="https://www.facebook.com/gaming/yeebetgaming" target={"_blank"}>
                  <FaFacebookF className="footer-socialIcon" />
                </a>
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                <a href="https://www.instagram.com/" target={"_blank"}>
                  <FaInstagram className="footer-socialIcon" />
                </a>
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                <a href="https://www.linkedin.com/company/89906358/" target={"_blank"}>
                  <FaLinkedin className="footer-socialIcon" />
                </a>
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                <a href="https://twitter.com/YeebetG" target={"_blank"}>
                  <FaTwitter className="footer-socialIcon" />
                </a>
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                <a href="https://t.me/igaming2u" target={"_blank"}>
                  <FaTelegram className="footer-socialIcon" />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="justify-content-center align-items-center pt-4">
          <Col lg={6} md={11} sm={11} className="mb-3">
            <div className="text-center text-lg-left">
              <span className="copywriteText">
                Copyright © 2022 Yeebet Limited. All Right Reserved.
              </span>
            </div>
          </Col>
          <Col lg={6} md={11} sm={11} className="mb-3">
            <Row className="justify-content-end align-items-center pt-4">
              <Col lg={3} md={4} sm={3} xs={6} className="mb-2 borderRightFooter">
                <div className="text-center text-lg-right">
                  <a href="/privacy-policy">
                    <span className="copywriteText">
                      Privacy Policy
                    </span>
                  </a>
                </div>
              </Col>
              <Col lg={3} md={4} sm={3} xs={6} className="mb-2 borderRightFooter">
                <div className="text-center text-lg-right">
                  <a href="/cookie-policy">
                    <span className="copywriteText">
                      Cookie Policy
                    </span>
                  </a>
                </div>
              </Col>
              <Col lg={4} md={4} sm={4} xs={12} className="mb-2">
                <div className="text-center text-lg-right">
                  <a href="/terms-condition">
                    <span className="copywriteText">
                      Terms & Conditions
                    </span>
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DarkFooter;
