import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Modal from 'react-modal';
// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    UncontrolledTooltip,
    Col,
    Row,
    Input
} from "reactstrap";
import "../../assets/css/mainNavBar.scss";
import { FaAngleDown, FaArrowDown, FaArrowRight, FaChevronDown, FaChevronRight, FaGlobe } from "react-icons/fa";

const submenu = [
    { title: 'Yeebet Live', path: '/yeebet-live', parent: 'about', child: false },
    { title: 'DK Gaming', path: 'https://dkgaming.asia/', parent: 'about', child: false },
    { title: 'Metapay', path: 'https://metapay168.net/', parent: 'about', child: false },
    { title: 'MetaSoft', path: 'http://metasoftapi.com/', parent: 'about', child: false },
    { title: 'Yeebet News', path: 'https://yeebet.info/', parent: 'about', child: false },
    { title: 'MGC Slots', path: 'http://mgcslot.com/', parent: 'about', child: false },
    { title: 'UU Slots', path: 'https://uuslots.info/', parent: 'about', child: false },

    { title: 'Account Types', path: '/account-types', parent: 'platforms', child: false },
    { title: 'Social Trading', path: '/social-trading', parent: 'platforms', child: false },
    { title: 'Meta Trader 5', path: '/metatrader5', parent: 'platforms', child: false },
    { title: 'MT5-Web Terminal', path: '/mt5-web-terminal', parent: 'platforms', child: false },
    { title: 'Rebate Calculator', path: '/rebate-calculator', parent: 'tools', child: false },
    { title: 'Deposit & Withdrawal', path: '/deposit-and-withdrawal', parent: 'tools', child: false },
    { title: 'Market info & Live Chart ', path: 'market-info-live-chart', parent: 'tools', child: true },
    { title: 'Economic Calendar', path: '/economic-calendar', parent: 'market-info-live-chart', child: false },
    { title: 'Economic News', path: '/economic-news', parent: 'market-info-live-chart', child: false },
    { title: 'Introducing Broker Programs', path: '/introducing-broker-programs', parent: 'partners', child: false },
    { title: 'IB Contest', path: '/ib-contest', parent: 'partners', child: false },
]

const lang = [
    { name: 'Arabic', code: 'ar' },
    { name: 'Chinese (Simplified)', code: 'zh-CN' },
    { name: 'Chinese (Traditional)', code: 'zh-TW' },
    { name: 'Dutch', code: 'nl' },
    { name: 'English', code: 'en' },
    { name: 'Filipino', code: 'tl' },
    { name: 'French', code: 'fr' },
    { name: 'German', code: 'de' },
    { name: 'Italian', code: 'it' },
    { name: 'Japanese', code: 'ja' },
    { name: 'Malay', code: 'ms' },
    { name: 'Portuguese', code: 'pt' },
    { name: 'Russian', code: 'ru' },
    { name: 'Spanish', code: 'es' },
    { name: 'Thai', code: 'th' },
    { name: 'Vietnamese', code: 'vi' },
]

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw',
        height: '60vh',
        backgroundColor: '#05010E',
        borderColor: 'transparent',
        borderRadius: '5px',
    },
    overlay: {
        backgroundColor: "rgba(255,255,255,0.1)",
        zIndex: '99999'
    }
};

function MainNavbar(props) {
    const location = useLocation();
    const history = useHistory();
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [menu, setMenu] = React.useState('');
    const [childMenu, setChildMenu] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [selectedLang, setSelectedLang] = React.useState('en')
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [path, setPath] = React.useState('/');

    useEffect(() => {
        setPath(location.pathname);
    }, [location]);

    useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop >= 10 ||
                document.body.scrollTop > 399
            ) {
                setNavbarColor("");
            } else if (
                document.documentElement.scrollTop < 10 ||
                document.body.scrollTop < 400
            ) {
                setNavbarColor("navbar-transparent");
            }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });

    const changeTheme = () => {

    }

    const closeDrawer = (path) => {
        setChecked(false);
        history.push(path)
    }

    const handleChange = (e) => {
        setChecked(e.target.checked)
        if (!e.target.checked) {
            setMenu('');
        }
    }

    useEffect(() => {
        document.getElementById('openDropdown-lg').addEventListener('change', () => {
            document.querySelector('.fa-angle-down-lg').classList.toggle('rotate-dropdown-arrow');
        });

        document.querySelector('.lang-dropdown-menu-lg').addEventListener('click', (e) => {
            document.getElementById('openDropdown-lg').checked = false;
            document.getElementById('openDropdown-lg').dispatchEvent(new Event('change'));
        });
    });

    const selectMenuMobile = (parent) => {
        if (parent === menu) {
            setMenu('');
            setChildMenu('');
        } else {
            setMenu(parent);
            setChildMenu('');
        }
    }

    const selectChildMenuMobile = (child) => {
        if (child === childMenu) {
            setChildMenu('');
        } else {
            setChildMenu(child);
        }
    }

    const selectLang = (val) => {
        setSelectedLang(val);
        closeModal();
    }

    const openModal = () => {
        setIsOpen(!modalIsOpen);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar className={"fixed-top " + navbarColor} expand="lg" style={{ background: "rgba(5, 4, 8, 0.35)" }}>
                <div className="d-flex align-items-center"></div>
                <div className="navbar-translate mx-lg-3">
                    <NavbarBrand href="/" id="navbar-brand" >
                        <img src={require("../../assets/img/logo.png").default} className="img-fluid logo-header" alt="logo" />
                    </NavbarBrand>

                    <div className="align-items-center justify-content-center d-block d-lg-none">
                        <div className="d-flex justify-content-center align-items-center hamburger-container">
                            <div className="px-2 d-none d-lg-block d-md-block">
                                <span className={`font-weight-400 font-16 text-uppercase text-white`}>Menu</span>
                            </div>
                            <div>
                                <input id="hamburger1" className="hamburger" type="checkbox" />
                                <label className={`hamburger mb-0 ${props.theme}`} htmlFor="hamburger1">
                                    <i className={`${props.theme}`}></i>
                                </label>
                                <section className={`drawer-list ${props.theme}`}>
                                    <Container>
                                        <Row className="mt-4">
                                            <Col lg={4} md={4} sm={12} xs={12}>
                                                <ul className="mobile-menu-list">
                                                    <li>
                                                        <a href="/index" className={`text-left font-600 font-16 ${props.theme} ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"}`}>HOME</a>
                                                    </li>
                                                    <li>
                                                        <a href="/about-us" className={`text-left font-600 font-16 ${props.theme} ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"}`}>ABOUT US</a>
                                                    </li>
                                                    {/* <li>
                                                        <a href="/our-brands" className={`text-left font-600 font-16 ${props.theme} ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"}`}>OUR BRANDS</a>
                                                    </li> */}
                                                                                                        <li className="dropdown-mobile">
                                                        <a onClick={() => { selectMenuMobile('platforms'); selectChildMenuMobile(''); }} className={`text-left font-600 font-16 d-flex justify-content-between align-items-center ${props.theme} ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"} ${menu === 'platforms' ? "active" : ""}`}>
                                                            <a href="/our-brands" className="px-0" style={{ width: "fit-content" }}>OUR BRANDS</a>
                                                            <FaArrowDown className="pull-right mb-2" />
                                                        </a>
                                                        <ul className={`dropdown-mobile-menu dropdown-mobile-intial level-1 list-1 ${menu === 'platforms' ? "" : 'panel-collapse collapse'}`}>
                                                            <li>
                                                                <Link to="/yeebet-live" className={`text-left font-600 font-16 ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"}`}>Yeebet Live</Link>
                                                            </li>
                                                            <li>
                                                                <a href="https://dkgaming.asia/" target={"_blank"} className={`text-left font-600 font-16 ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"}`}>DK Gaming</a>
                                                            </li>
                                                            <li>
                                                                <a href="https://metapay168.net/" target={"_blank"} className={`text-left font-600 font-16 ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"}`}>Metapay</a>
                                                            </li>
                                                            <li>
                                                                <a href="http://metasoftapi.com/" target={"_blank"} className={`text-left font-600 font-16 ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"}`}>MetaSoft</a>
                                                            </li>
                                                            <li>
                                                                <a href="https://yeebet.info/" target={"_blank"} className={`text-left font-600 font-16 ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"}`}>Yeebet News</a>
                                                            </li>
                                                            <li>
                                                                <a href="http://mgcslot.com/" target={"_blank"} className={`text-left font-600 font-16 ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"}`}>MGC Slots</a>
                                                            </li>
                                                            <li>
                                                                <a href="https://uuslots.info/" target={"_blank"} className={`text-left font-600 font-16 ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"}`}>UU Slots</a>
                                                            </li>

                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="/our-games" className={`text-left font-600 font-16 ${props.theme} ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"}`}>OUR GAMES</a>
                                                    </li>
                                                    <li>
                                                        <a href="/news" className={`text-left font-600 font-16 ${props.theme} ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"}`}>NEWS</a>
                                                    </li>
                                                    <li>
                                                        <a href="/contact-us" className={`text-left font-600 font-16 ${props.theme} ${props.theme === 'dark' ? "font-white-1" : "font-grey-2"}`}>CONTACT US</a>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                        <Row className="justify-content-start align-items-start mt-xl-10vh">
                                            <Col lg={10} xs={10}>
                                                <ul>
                                                    <li className="mb-0">
                                                        <Button
                                                            className={`nav-link DemoDiv text-center px-3 py-2 my-0`}
                                                            href="https://www.yeebet.vip/"
                                                            target="_blank"
                                                        >
                                                            Demo
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>

                                    </Container>
                                </section>
                            </div>
                        </div>
                    </div>

                </div>
                <Collapse className="justify-content-end" isOpen={collapseOpen} navbar>
                    <Nav navbar>
                        <NavItem>
                            <Button
                                className={`DemoDiv text-center px-3 py-2 my-0`}
                                href="https://www.yeebet.vip/"
                                target="_blank"
                            >
                                Demo
                            </Button>
                        </NavItem>
                    </Nav>
                </Collapse>
                <div className="align-items-center justify-content-center mx-3 d-none d-lg-block">
                    <div className="d-flex justify-content-center align-items-center hamburger-container">
                        <div className="px-2 d-none d-lg-block d-md-block">
                            <span className={`font-weight-400 font-16 text-uppercase text-white`}> Menu</span>
                        </div>
                        <div>
                            <input id="hamburger" className="hamburger" type="checkbox" checked={checked} onChange={handleChange} />
                            <label className={`hamburger mb-0 ${props.theme}`} htmlFor="hamburger">
                                <i className={`${path === '/' ? "homeHamburger" : ""} ${checked ? 'checked' : 'unchecked'} ${props.theme}`}></i>
                            </label>
                            <section className={`drawer-list ${props.theme}`}>
                                <Row className="justify-content-center mt-xl-10vh">
                                    <Col lg={10} md={10} sm={12} xs={12}>
                                        <div className="pull-right">
                                            <ul className="top-menu">
                                                <li>
                                                    <div className="d-flex w-100 align-items-start">

                                                        <div className="lang-dropdown-container position-relative">

                                                            <input type="checkbox" id="openDropdown-lg" hidden />

                                                            <div className={`lang-dropdown-menu-lg ${props.theme}`}>
                                                                <div className="lang-menu-container text-center w-100">
                                                                    {lang.map((item, i) => (
                                                                        <span key={i} className={`font-weight-500 font-16 ${props.theme === 'dark' ? 'text-white' : 'font-black-1'}`}>{item.name}</span>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <ul className="drawer-list-group">
                                            <li><a href="/index" className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize ${props.theme}`}>HOME</a></li>
                                            <li><a href="/about-us" className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize ${props.theme}`}>ABOUT US</a></li>
                                            {/* <li><a href="/our-brands" className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize ${props.theme}`}>OUR BRANDS</a></li> */}
                                            <li onMouseEnter={() => { setMenu('about'); setChildMenu(''); }}><a className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize d-flex align-items-center w-100 justify-content-between ${props.theme}`} href="/our-brands"> OUR BRANDS <FaArrowRight color={props.theme === 'dark' ? '#ffffff' : '#575757'} className="pull-right" /></a></li>
                                            <li><a href="/our-games" className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize ${props.theme}`}>OUR GAMES</a></li>
                                            <li><a href="/news" className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize ${props.theme}`}>NEWS</a></li>
                                            <li><a href="/contact-us" className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize ${props.theme}`}>CONTACT US</a></li>
                                        </ul>
                                    </Col>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <ul className="drawer-list-group">
                                            {menu ? (
                                                submenu.filter(i => i.parent === menu).map((item, j) => (
                                                    !item.child ? (
                                                        <li key={j} onMouseEnter={() => setChildMenu('')}><a href={item.path} target={(item.path).includes("http") ? "_blank" : ""} className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize ${props.theme}`}> {item.title}</a></li>
                                                    ) : (
                                                        <li key={j} onMouseEnter={() => setChildMenu(item.path)}><a className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize ${props.theme}`}> {item.title} <FaArrowRight color={props.theme === 'dark' ? '#ffffff' : '#575757'} className="pull-right" /></a></li>
                                                    )
                                                ))
                                            ) : null}
                                        </ul>
                                    </Col>
                                    <Col lg={3} md={3} sm={12} xs={12}>
                                        <ul className="drawer-list-group">
                                            {childMenu ? (
                                                submenu.filter(i => i.parent === childMenu).map((item, j) => (
                                                    <li key={j}><a onClick={() => closeDrawer(item.path)} className={`text-left pt-lg-4 font-weight-600 font-25 text-capitalize ${props.theme}`}> {item.title}</a></li>
                                                ))
                                            ) : null}
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="language-options country-options">
                        {lang.map((item, i) => (
                            <div key={i} className="font-weight-500 font-16 text-white px-2 py-2 language-option country-option" data-country={item.name} onClick={() => selectLang(item.code)}>
                                {item.name}
                            </div>
                        ))}
                    </div>
                </Modal>
            </Navbar>
        </>
    );
}
export default MainNavbar;

